<template>
  <div id="app">
    <!-- <div class="pj-ewm-box" v-show="this.getMenuAndActive().activeMenuId.isClose" >
       <div class="pj-ewm">
          <div class="pj-close">
            <span>履职评价二维码</span>
            <i class="el-icon-close"></i>
          </div>
          <i>
            <img :src="this.getMenuAndActive().activeMenuId.ewmImg" alt="">
          </i>
          <span>
             使用微信扫描当前二维码进行评价
          </span>
       </div>
    </div> -->
    <div class="header-bj">
    </div>
    <div class="nav-box">
      <nav class="container">
        <router-link to="/">首页</router-link>
        <router-link to="/synthesizeList">综合新闻</router-link>
        <router-link to="/partyOrganization">机构设置</router-link>
        <router-link to="/performActivity">履职活动</router-link>
        <router-link to="/notice">通知公告</router-link>
        <router-link to="/personnelRemoval">人事任免</router-link>
        <router-link to="/partyWork">党建工作</router-link>
        <router-link to="/PeopleCongress">乡镇人大</router-link>
        <router-link to="/learningGarden">学习园地</router-link>
      </nav>
    </div>
    <router-view/>
    <footerView></footerView>
  </div>
</template>
<script>
import Vue from 'vue'
import footerView from './components/FooterView'
export default {
  data() {
    return {
      rep_sort:[],
      announcement:null,
    }
  },
  components: {
    footerView
  },
  mounted() { 
    console.log(
      "获取vuex数据",
      this.getMenuAndActive().activeMenuId
    )
   
  },
  methods: {
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.nav-box{
  background: #ac0a0a;
}
nav {
 height: 54px;
 display: flex;
 justify-content: space-between;
 align-items: center;
}

nav a {
  color: #fff;
}

nav a.router-link-exact-active {
  height: 54px;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 3px solid #fff;
  box-sizing: border-box;
}
</style>
@import '@/assets/css/common.css'