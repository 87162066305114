<!-- @format -->
<template>
  <div class="home-rd-item">
    <div v-if="msg">
      <ul>
        <li v-for="item in msg[0]" :key="item.id" @click="toDeputies(item.id)">
          <a>
            <i class="imgbox_a">
              <img :src="imgUrl + item.avatar" alt="" />
            </i>
            <span>
              <h1>{{ item.rep_name }}</h1>
              <h2>{{ item.unit }}</h2>
            </span>
          </a>
        </li>
      </ul>
      <div class="reading-more" @click="totownpeopleList(gangu_towns_area[msg[1]].id)">
        查看更多
      </div>
    </div>

    <div v-else>
      暂无数据
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import * as api from "@/utils/http";
export default {
  props: ['msg'],
  props: {
    msg: {
      type: Array,
      default: '这是默认数据'
    },
  },
  data() {
    return {
      imgUrl: api.baseURL,
      gangu_towns_area: [], //乡镇列表
    };
  },
  components: {},
  mounted() {
    this.init();
    console.log("msg", this.msg )
  },
  methods: {

    // 跳转人大列表
    topeopleList() {
      this.$router.push(`/peoplelist`);
    },
    // 跳转乡镇人大履职
    toDeputies(id) {
      this.$router.push(`/towndeputies/${id}`);
    },
    // 跳转乡镇人大列表
    totownpeopleList(id) {
      this.$router.push(`/townPeopleList/${id}`);
    },
   // 跳转人大履职
   toDeputies(id) {
      this.$router.push(`/deputies/${id}`);
    },
    async init() {
      await api
        .post("/dictController/getDictListByType", { type: "gangu_towns_area" })
        .then((res) => {
          console.log("乡镇列表", res.data);
          this.gangu_towns_area = res.data;
          api.post("/representativesController/selectRepListByType", {
            limit: 5,
            page: 1,
            rep_sort: "ceb64afa806c4b06bde058a1ff4264ad",
            towns: this.gangu_towns_area[this.peopleIndex].id
          })
            .then((res) => {
              console.log("乡镇人大代表==", res.data, this.gangu_towns_area[this.peopleIndex].id);
              this.townsPeople = res.data;
            });
        });
    }



  },
};
</script>
<style scoped>
.home-rd-item ul {
  margin-right: -18px;
  overflow: hidden;
}

.home-rd-item ul li {
  width: 20%;
  padding-right: 18px;
  float: left;
  box-sizing: border-box;
}

.home-rd-item ul li a {
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  width: 100%;
  display: block;
  padding: 18px;
  box-sizing: border-box;
  overflow: hidden;
}

.home-rd-item ul li a i.imgbox_a {
  padding-bottom: 132%;
}

.home-rd-item ul li a span h1 {
  font-size: 16px;
  margin: 0px;
  margin-top: 15px;
}

.home-rd-item ul li a span h2 {
  font-size: 15px;
  color: #999999;
  font-weight: normal;
  margin: 0px;
  margin-top: 5px;
  height: 40px;
}

.reading-more {
  border: 1px solid #ccc;
  width: 180px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  margin: 0px auto;
  margin-top: 24px;
}
.reading-more:hover{
  cursor: pointer;
  color: #db4836;
  border: 1px solid #db4836;
}

</style>
@import '@/assets/css/common.css'
