<template>
  <div class="content">
    <div class="container">
      <div class="rd-item">
        <ul>
          <li v-for="item in peopleList" :key="item.id">
            <a @click="toDeputies(item.id)">
              <div v-if="item.avatar">
                <i class="imgbox_a">
                  <img :src="imgUrl + item.avatar" alt="">
                </i>
              </div>
              <div v-else>
                <i class="imgbox_a">
                  <img src="@/images/mr-pic.jpg" alt="">
                </i>
              </div>
             
              <span>
                <h1>{{ item.rep_name }}</h1>
                <h2>{{ item.unit }}</h2>
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="page">
        <el-pagination background layout="prev, pager, next" :total="pageTotal" :page-size="perPage"
          :current-page="currentPage" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import picNews from '../../components/PicNews'
import Vue from 'vue'
Vue.component('picNews', picNews)
import * as api from "@/utils/http";
export default {
  name: 'HomeView',
  data() {
    return {
      peopleList: [],
      imgUrl: "",
      perPage: 10,
      pageTotal: 0,
      currentPage: 1,
    };
  },
  components: {

  },
  methods: {
    // 跳转人大履职
    toDeputies(id) {
      this.$router.push(`/deputies/${id}`);
    },

    //当前页改变
    currentChange: function (page) {
      this.currentPage = page;
      this.getcurrentPage();
    },
    getcurrentPage: function () {
      api
        .post("/representativesController/selectRepListByType",
          {
            page: this.currentPage,
            limit: this.perPage,
            rep_sort: this.$route.params.id
          })
        .then((res) => {
          console.log("shhe", res);
          this.imgUrl = api.baseURL
          this.peopleList = res.data
        });
    }

  },
  mounted() {
    api
      .post("/representativesController/selectRepListByType",
        {
          page: this.currentPage,
          limit: this.perPage,
          rep_sort: this.$route.params.id
        })
      .then((res) => {
        console.log("shhe", res);
        this.imgUrl = api.baseURL
        this.peopleList = res.data
        this.pageTotal = res.rowCount
      });
  }
}
</script>
<style scoped>
.box {
  background: #fff;
  padding: 24px;
  padding-bottom: 0px;
  box-sizing: border-box;
  overflow: hidden;
}

.rd-item {
  padding-top: 32px;
}

.rd-item ul {
  margin-right: -18px;
  overflow: hidden;
}

.rd-item ul li {
  width: 20%;
  padding-right: 18px;
  float: left;
  box-sizing: border-box;
  margin-bottom: 32px;
}

.rd-item ul li a {
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  width: 100%;
  display: block;
  padding: 18px;
  box-sizing: border-box;
  overflow: hidden;
}

.rd-item ul li a i.imgbox_a {
  padding-bottom: 132%;
}

.rd-item ul li a span h1 {
  font-size: 20px;
  margin: 0px;
  margin-top: 15px;
}

.rd-item ul li a span h2 {
  font-size: 16px;
  color: #999999;
  font-weight: normal;
  margin: 0px;
  margin-top: 5px;
  height: 42px;
	overflow: hidden;	
	text-overflow: ellipsis;	
	display: -webkit-box;	
	-webkit-line-clamp: 2;
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}
.page{
  padding-bottom: 32px;
}
</style>
@import '@/assets/css/common.css'