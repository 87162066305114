import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import vuexF from '@/utils/vuex'
import moment from "moment"
import 'moment/locale/zh-cn'
moment.locale('zh-cn');   
Vue.filter('time', function(value, str = 'YYYY年MM月DD日') {
  return moment(value*1000).format(str)
})
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/common.css'
Vue.prototype.$moment = moment;
Vue.config.productionTip = false
Vue.use(ElementUI);
import axios from 'axios'
Vue.mixin(vuexF)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
