<template>
  <div class="content">

    <div class="container">
      <div class="box box-main2">
        <div class="home-xz-name tit-mb">
          <span v-for="(item, index) in gangu_towns_area" :key="item.id" @click="townspeolple(index)" :class="peopleIndex === index ? 'active' : ''">
            {{ item.value }}
          </span>
        </div>
        <div v-if="peopleIndex == 0">
          <div class="home-rd-item">
            <ul>
              <li v-for="item in townsPeople" :key="item.id" @click="toTownDeputies(item.id)">
                <a>
                  <i class="imgbox_a">
                    <img :src="imgUrl + item.title_image" alt="" />
                  </i>
                  <span>
                    <h1>{{ item.title }}</h1>
                    <h2>职务：{{ item.zhiwu }}</h2>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <townsPeople :msg="this.townsPeople"></townsPeople>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as api from "@/utils/http";
import townsPeople from '@/views/PeopleCongress/TownsPeople'
import Vue from 'vue'
// Vue.component('townsPeople', townsPeople)

export default {
  name: 'HomeView',
  data() {
    return {
      rep_sort: [],
      gangu_towns_area: [], //乡镇列表
      townsPeople: null, //乡镇人大
      synthesizeNews: null,  //综合新闻
      notice_type: [], //通知 
      provincePeople: null,
      cityPeople: null,
      countyPeople: null,
      townPeople: null,
      announcement: null,
      partyBuilding: null, //党建工作
      performActivity: null,  //履职活动
      peopleIndex: 0,
      imgUrl: ""
    }
  },
  components: {
    townsPeople
  },
  mounted() {
    this.init();
  },
  methods: {
    // 跳转人大履职
    toDeputies(id) {
      this.$router.push(`/deputies/${id}`);
    },
     // 跳转乡镇人大履职
     toTownDeputies(id) {
      this.$router.push(`/towndeputies/${id}`);
    },

    // 乡镇人大列表切换
    townspeolple(num) {
      this.peopleIndex = num;
      api.post("/ganguTownsAreaController/getGanguTownsAreaList", {
        limit: 5,
        page: 1,
        area_type: this.gangu_towns_area[this.peopleIndex].id
      }).then((res) => {
        this.imgUrl = api.baseURL
        console.log("乡镇人大列表--", this.townsPeople,res);
        this.townsPeople = res.data
      })
    },
    async init() {
      await api
        .post("/dictController/getDictListByType", { type: "rep_sort" })
        .then((res) => {
          this.imgUrl = api.baseURL
          console.log("人大类型", res.data);
          this.rep_sort = res.data;
        });
      await api
        .post("/representativesController/selectRepListByType", {
          limit: 5,
          page: 1,
          rep_sort: this.rep_sort[4].id
        })
        .then((res) => {
          console.log("乡镇人大代表", res.data);
          this.townPeople = res.data
        });
      await api
        .post("/dictController/getDictListByType", { type: "gangu_towns_area" })
        .then((res) => {
          console.log("乡镇列表", res.data);
          this.gangu_towns_area = res.data;
        });
      await api
        .post("/ganguTownsAreaController/getGanguTownsAreaList", {
          limit: 7,
          page: 1,
          area_type: this.gangu_towns_area[this.peopleIndex].id
        })
        .then((res) => {
          console.log("乡镇人大列表", this.peopleIndex);
          this.townsPeople = res.data
        });

    }
  }
}
</script>
<style scoped>
.content {
  background: #f3edd0;
}

.box {
  background: #fff;
  padding: 24px;
  padding-bottom: 0px;
  box-sizing: border-box;
  overflow: hidden;
}

.box-left {
  width: 294px;
  margin-right: 24px;
  box-sizing: border-box;
  background: #f4f0f0;
  padding: 24px;
  box-sizing: border-box;
}

.member-pic {
  width: 214px;
  border: 14px solid #fff;
}

.member-pic i.imgbox_a {
  padding-bottom: 131.78%;
}

.member-inf h1 {
  text-align: left;
  margin: 0px;
  margin-top: 10px;
}

.member-inf h1 span:first-child {
  font-size: 20px;
  margin-right: 5px;
  font-weight: bolder;
  color: #333333;
}

.member-inf h1 span {
  font-size: 16px;
  font-weight: normal;
  color: #333333;
}

.member-inf h2 {
  margin: 0px;
  margin-top: 6px;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
  color: #333333;
}

.evaluation {
  margin-top: 46px;
}

.evaluation-tit {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: bolder;
  text-align: left;
  position: relative;
  padding-left: 12px;
}

.evaluation-tit::before {
  content: "";
  width: 4px;
  height: 20px;
  position: absolute;
  left: 0px;
  background: #d83d32;
}

.evaluation-list-tit {
  overflow: hidden;
  margin-top: 12px;
}

.evaluation-list-tit span {
  width: 33.33%;
  display: block;
  float: left;
  text-align: center;
}

.evaluation-list-inf {
  margin-top: 8px;
  display: flex;
  justify-content: space-around;
}

.evaluation-list-inf span {
  width: 29px;
  height: 29px;
  display: flex;
  background: url(../../images/star.png);
}

.box-right {
  border: 1px solid #e4e4e4;
  min-height: 800px;
  overflow: hidden;
  padding: 24px;
  box-sizing: border-box;
}

.news-list ul li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 24px;
  margin-top: 24px;
}

.news-list ul li:first-child {
  margin-top: 0px;
}

.news-list-pic {
  width: 212px;
  margin-right: 20px;
  flex-shrink: 0;
}

.news-list-pic i.imgbox_a {
  padding-bottom: 66.04%;
}

.news-list-inf {
  text-align: left;
  flex-grow: 1;
}

.news-list-inf h1 {
  font-size: 20px;
  margin: 0px;
}

.news-list-inf h2 {
  font-size: 16px;
  margin: 0px;
  margin-top: 8px;
  font-weight: normal;
  line-height: 1.5;
  color: #333333;
}

.page {
  margin-top: 32px;
  margin-bottom: 8px;
}

.page ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page ul li span {
  padding: 8px 10px;
  border: 1px solid #ccc;
  margin: 0px 3px;
  font-size: 16px;
}


.home-rd-item {
  padding-bottom: 38px;
}

.home-rd-item ul {
  margin-right: -18px;
  overflow: hidden;
}

.home-rd-item ul li {
  width: 20%;
  padding-right: 18px;
  float: left;
  box-sizing: border-box;

}

.home-rd-item ul li a {
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  width: 100%;
  display: block;
  padding: 18px;
  box-sizing: border-box;
  overflow: hidden;
}

.home-rd-item ul li a i.imgbox_a {
  padding-bottom: 132%;
}

.home-rd-item ul li a span h1 {
  font-size: 16px;
  margin: 0px;
  margin-top: 15px;
}

.home-rd-item ul li a span h2 {
  font-size: 15px;
  color: #999999;
  font-weight: normal;
  margin: 0px;
  margin-top: 5px;
}

.home-xz-name {
  background: #f3f3f3;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
}

.home-xz-name span {
  padding: 10px 10px;
  font-size: 16px;
}
.home-xz-name span.active{
  color: #db4836;
  font-weight: bolder;
}
</style>
@import '@/assets/css/common.css'