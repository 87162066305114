<template>
  <div class="content">
    <div class="container">
       <picNews></picNews>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import picNews from '../../components/PicNews'
import Vue from 'vue'
Vue.component('picNews',picNews)

export default {
  name: 'HomeView',
  components: {
    picNews
  }
}
</script>
<style scoped>
.content {
  background: #f3edd0;
}

.box {
  background: #fff;
  padding: 24px;
  padding-bottom: 0px;
  box-sizing: border-box;
  overflow: hidden;
}

.box-left {
  width: 294px;
  margin-right: 24px;
  box-sizing: border-box;
  background: #f4f0f0;
  padding: 24px;
  box-sizing: border-box;
}

.member-pic {
  width: 214px;
  border: 14px solid #fff;
}

.member-pic i.imgbox_a {
  padding-bottom: 131.78%;
}

.member-inf h1 {
  text-align: left;
  margin: 0px;
  margin-top: 10px;
}

.member-inf h1 span:first-child {
  font-size: 20px;
  margin-right: 5px;
  font-weight: bolder;
  color: #333333;
}

.member-inf h1 span {
  font-size: 16px;
  font-weight: normal;
  color: #333333;
}

.member-inf h2 {
  margin: 0px;
  margin-top: 6px;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
  color: #333333;
}

.evaluation {
  margin-top: 46px;
}

.evaluation-tit {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: bolder;
  text-align: left;
  position: relative;
  padding-left: 12px;
}

.evaluation-tit::before {
  content: "";
  width: 4px;
  height: 20px;
  position: absolute;
  left: 0px;
  background: #d83d32;
}

.evaluation-list-tit {
  overflow: hidden;
  margin-top: 12px;
}

.evaluation-list-tit span {
  width: 33.33%;
  display: block;
  float: left;
  text-align: center;
}

.evaluation-list-inf {
  margin-top: 8px;
  display: flex;
  justify-content: space-around;
}

.evaluation-list-inf span {
  width: 29px;
  height: 29px;
  display: flex;
  background: url(../../images/star.png);
}

.box-right {
  border: 1px solid #e4e4e4;
  min-height: 800px;
  overflow: hidden;
  padding: 24px;
  box-sizing: border-box;
}

.news-list ul li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 24px;
  margin-top: 24px;
}

.news-list ul li:first-child {
  margin-top: 0px;
}

.news-list-pic {
  width: 212px;
  margin-right: 20px;
  flex-shrink: 0;
}

.news-list-pic i.imgbox_a {
  padding-bottom: 66.04%;
}

.news-list-inf {
  text-align: left;
  flex-grow: 1;
}

.news-list-inf h1 {
  font-size: 20px;
  margin: 0px;
}

.news-list-inf h2 {
  font-size: 16px;
  margin: 0px;
  margin-top: 8px;
  font-weight: normal;
  line-height: 1.5;
  color: #333333;
}
.page{
  margin-top: 32px;
  margin-bottom: 8px;
}
.page ul{
   display: flex;
   justify-content: center;
   align-items: center;
}
.page ul li span{
  padding: 8px 10px;
  border: 1px solid #ccc;
  margin: 0px 3px;
  font-size: 16px;
}
</style>
@import '@/assets/css/common.css'