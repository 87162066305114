<template>
  <div class="content">
    <div class="container">
      <div class="box">
          <div class="news-details">
            <div class="news-details-tit">
              <h1>{{ detail.title}}</h1>
              <h2>
                <span> {{detail.title}}</span>
                <span>发稿日期：{{detail.creationTime | time('YYYY/MM/DD') }}</span>
              </h2>
            </div>
            <div class="news-details-main" v-html="detail.jumpText">
             
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as api from "@/utils/http";
export default {
  data() {
    return {
      detail:[]
    }
  },
  components: {
  },
  mounted() {
    this.init()
  },
  methods: {
   
    //html转化过滤其他标签方法
    filterHtml: function (str) {
      let reg = new RegExp("<[^>]+>", "gi");
      if (typeof str != "string") {
        //不是字符串
        return str;
      }
      str = str.replace(/&nbsp;/gi, "");
      return str.replace(reg, "");
    },
    async init() {
      await api
        .post("/movePersonnelController/selectMovePersonnelById", {
          movePersonnelId: this.$route.params.id,
        })
        .then((res) => {
          this.detail = res.data.movePersonnelModel
          console.log("人事数据", res);
        });
    }

  }
}
</script>
<style scoped>
.content {
  background: #f3edd0;
}

.box {
  background: #fff;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
}
.box-right {
  border: 1px solid #e4e4e4;
  min-height: 800px;
  overflow: hidden;
  padding: 24px;
  box-sizing: border-box;
}

.news-list ul li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 24px;
  margin-top: 24px;
}

.news-list ul li:first-child {
  margin-top: 0px;
}

.news-list-pic {
  width: 212px;
  margin-right: 20px;
  flex-shrink: 0;
}

.news-list-pic i.imgbox_a {
  padding-bottom: 66.04%;
}

.news-list-inf {
  text-align: left;
  flex-grow: 1;
}

.news-list-inf h1 {
  font-size: 20px;
  margin: 0px;
}

.news-list-inf h2 {
  font-size: 16px;
  margin: 0px;
  margin-top: 8px;
  font-weight: normal;
  line-height: 1.5;
  color: #333333;
}

.news-list-inf h3 {
  font-size: 16px;
  margin: 0px;
  margin-top: 8px;
  font-weight: normal;
  line-height: 1.7;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.page {
  margin-top: 32px;
  margin-bottom: 8px;
}

</style>
@import '@/assets/css/common.css'