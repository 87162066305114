import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home/HomeView.vue'
import ComprehensiveNews from '../views/ComprehensiveNews/ComprehensiveNews.vue'
import PerformActivity from '../views/PerformActivity/PerformActivity.vue'//履职活动
import PerformDetail from '../views/PerformActivity/PerformDetail.vue'//履职活动详情

import Notice from '../views/Notice/Notice.vue'//通知公告
import Newsdetail from '../views/Notice/Newsdetail.vue'  //通知公告详情
import PersonnelRemoval from '../views/PersonnelRemoval/PersonnelRemoval.vue' //人事任免
import PersonnelDetail from '../views/PersonnelRemoval/Detail.vue' //人事任免详情

import PartyWork from '../views/PartyWork/PartyWork.vue' //党建工作
import PartyDetail from '../views/PartyWork/PartyDetail.vue' //党建工作详情

import PeopleCongress from '../views/PeopleCongress/PeopleCongress.vue'
import LearningGarden from '../views/LearningGarden/LearningGarden.vue'
import PeopleList from '../views/People/PeopleList.vue'  //人大代表列表
import PeopleDetail from '../views/People/PeopleDetail.vue'  //人大代表详情
import PicNews from '../views/PicNews/PicNews.vue'  //图片新闻


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/detailsView',
    name: 'detailsView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DetailsView/DetailsView.vue')
  },
  {
    path: '/mechanism',
    name: 'Mechanism',
    component: () => import('../views/Mechanism/Mechanism.vue'),
    children: [
      {
        path: '/partyOrganization',
        name: 'PartyOrganization',
        component: () => import('../views/Mechanism/PartyOrganization.vue')
      },
      {
        path: '/adminOrganization',
        name: 'AdminOrganization',
        component: () => import('../views/Mechanism/AdminOrganization.vue')
      }
    ]
  },
  {
    path: '/comprehensiveNews',
    name: 'comprehensiveNews',
    component: ComprehensiveNews
  },
  {
    //履职活动
    path: '/performActivity',
    name: 'performActivity',
    component: PerformActivity
  },
  {
    //履职活动详情
    path: '/performActivity/performDetail/:id',
    name: 'PerformDetail',
    component: PerformDetail
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice
  },
  {
    path: '/personnelRemoval',
    name: 'PersonnelRemoval',
    component: PersonnelRemoval,
  },
  {
    path: '/detail/:id',
    name: 'PersonnelDetail',
    component: PersonnelDetail
  },
  {
    // 党建工作
    path: '/partyWork',
    name: 'PartyWork',
    component: PartyWork
  },
  // 党建工作详情
  {
    path: '/partywork/partydetail/:id',
    name: 'PartyDetail',
    component: PartyDetail
  },
  {
    path: '/peopleCongress',
    name: 'PeopleCongress',
    component: PeopleCongress
  },
  {
    path: '/learningGarden',
    name: 'LearningGarden',
    component: LearningGarden
  },
  {
    path: '/learningdetail/:id',
    name: 'LearningGarden',
    component: () => import('../views/LearningGarden/LearningDetail.vue')
  },
  {
    path: '/peopleList/:id',
    name: 'PeopleList',
    component: PeopleList
  },
  {
    path: '/countyPeopleList/:id',
    name: '县人大代表',
    component: () => import('../views/CountyPeople/CountyPeopleList.vue')
  },
  {
    path: '/townPeopleList/:id',
    name: '县镇人大代表',
    component: () => import('../views/TownPeople/TownPeopleList.vue')
  },
  {
    path: '/peopleDetail/:id',
    name: '代表详情',
    component: PeopleDetail
  },
  {
    path: '/picNews/:id',
    name: 'PicNews',
    component: PicNews
  },
  {
    path: '/newsdetail/:id',
    name: 'Newsdetail',
    component: Newsdetail
  },
  {
    path: '/deputies/:id',
    name: '人大代表履职',
    component: () => import('../views/Deputies/Deputies.vue'),
    children:[{
      path: '/',
      name: '人大代表履职列表',
      component: () => import('../views/Deputies/DeputiesList.vue')
    },
    {
      path: 'deputiesDetail/:id',
      name: '人大代表履职详情',
      component: () => import('../views/Deputies/DeputiesDetail.vue')
    },
  ]
  },
  {
    path: '/synthesizeList',
    name: '综合新闻',
    component: () => import('../views/SynthesizeNews/synthesizeList.vue')
  },
  {
    path: '/towndeputies/:id',
    name: '乡镇人大代表履职',
    component: () => import('../views/TownDeputies/TownDeputies.vue'),
    children:[{
      path: '/',
      name: '乡镇人大代表履职列表',
      component: () => import('../views/TownDeputies/TownDeputiesList.vue')
    },
    {
      path: 'deputiesDetail/:id',
      name: '乡镇人大代表履职详情',
      component: () => import('../views/TownDeputies/TownDeputiesDetail.vue')
    },
  ]
  },
]

const router = new VueRouter({
  routes
})

export default router
