<!-- @format -->
<template>
  <div class="home-rd-item">
    <ul v-if="msg">
      <li v-for="item in msg" :key="item.id" @click="toTownDeputies(item.id)">
        <a>
          <i class="imgbox_a">
            <img :src="imgUrl + item.title_image" alt="" />
          </i>
          <span>
            <h1>{{ item.title }}</h1>
            <h2>职务：{{ item.zhiwu }}</h2>
          </span>
        </a>
      </li>
    </ul>
    <div v-else>
      暂无数据
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import * as api from "@/utils/http";
export default {
  props: ['msg'],
  props: {
    msg: {
      type: Array,
      default: '这是默认数据'
    }
  },
  data() {
    return {
      imgUrl: "http://101.200.163.56:40006",
    };
  },
  components: {},
  mounted() {
    console.log("msg", this.msg)
  },
  methods: {

    // 跳转人大列表
    topeopleList() {
      this.$router.push(`/peoplelist`);
    },
  // 跳转乡镇人大履职
  toTownDeputies(id) {
      this.$router.push(`/towndeputies/${id}`);
    },
  },
};
</script>
<style scoped>
.home-rd-item {
  padding-bottom: 38px;
}

.home-rd-item ul {
  margin-right: -18px;
  overflow: hidden;
}

.home-rd-item ul li {
  width: 20%;
  padding-right: 18px;
  float: left;
  box-sizing: border-box;
}

.home-rd-item ul li a {
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  width: 100%;
  display: block;
  padding: 18px;
  box-sizing: border-box;
  overflow: hidden;
}

.home-rd-item ul li a i.imgbox_a {
  padding-bottom: 132%;
}

.home-rd-item ul li a span h1 {
  font-size: 1px;
  margin: 0px;
  margin-top: 15px;
}

.home-rd-item ul li a span h2 {
  font-size: 15px;
  color: #999999;
  font-weight: normal;
  margin: 0px;
  margin-top: 5px;
}
</style>
@import '@/assets/css/common.css'
