import VueCookies from 'vue-cookies'


/**
 * 设置全局过期时间
 * @param {*} dete 
 * @returns 
 */
export function setDete(dete = '7d') {
    return VueCookies.config(dete)
}

/**
 * 设置cookie并设置过期时间
 * @param {*} name cookie名称
 * @param {*} obj 存储对象
 * @param {*} dete 过期时间
 * @returns 
 */
export function setCookieAndDate(name, obj, dete) {
    VueCookies.set(name, JSON.stringify(obj), dete)
    return getCookie(name) ? true : false
}

/**
 * 设置cookie
 * @param {*} name cookie名称
 * @param {*} obj 存储对象
 * @returns 
 */
export function setCookie(name, obj) {
    VueCookies.set(name, JSON.stringify(obj))
    return getCookie(name) ? true : false
}

/**
 * 根据cookie名称获取value
 * @param {*} name cookie名称
 * @returns obj
 */
export function getCookie(name) {
    return VueCookies.get(name)
}

/**
 * 根据cookie名称删除value
 * @param {*} name cookie名称
 * @returns 
 */
export function removeCookie(name) {
    VueCookies.remove(name)
    return getCookie(name) ? false : true
}