<template>
  <div class="footer">
      <div class="container">
        <div class="footer-main">
          <div class="footer-txt">
            <h1>主办ss：甘谷县人民代表大会履职服务平台</h1>
            <h1>邮编:710000 电话:029—8888888邮箱:ggrd@163.com</h1>
            <h1>
              甘谷县人民代表大会履职服务平台版权所有
              备案许可证编号:陇ICP备22000000号-1
            </h1>
          </div>
          <div class="ewm">
            <i>
              <img src="@/images/ewm.jpg" alt="" />
            </i>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
    }
  },
  components: {
  },
  methods: {
  }
}
</script>
<style scoped>
</style>
@import '@/assets/css/common.css'