import axios from "axios";
// export const baseURL = 'http://101.200.163.56:40006'
// axios.defaults.baseURL = 'http://101.200.163.56:40006';
export const baseURL = 'https://www.ggrdw.gov.cn:40006'
axios.defaults.baseURL = 'https://www.ggrdw.gov.cn:40006';
axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers.common['XX-Device-Type'] = 'pc';
axios.defaults.timeout = 50000;

//  * 封装get请求
//  * @param url
//  * @param data
//  * @returns {Promise}
//  */

export function get(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: data })
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

// /**
//  * 封装post请求
//  * @param url
//  * @param data
//  * @returns {Promise}
//  */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

//  /**
//  * 封装patch请求
//  * @param url
//  * @param data
//  * @returns {Promise}
//  */

export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

//  /**
//  * 封装put请求
//  * @param url
//  * @param data
//  * @returns {Promise}
//  */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}
export function deletes(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.delete(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}
