<!-- @format -->

<template>
  <div class="home">
    <!-- <div class="search">
      <div class="container">
        <div class="search-main">
          <form action="">
            <input type="text" class="input-txt" placeholder="在搜索框中，您可以输入大代表姓名，职位或者所在地进行检索">
            <button type="button" class="input-button"></button>
          </form>
        </div>
                </div>
              </div> -->

    <div class="box">
      <div class="container">
        <div class="sign-box">
          <div class="sign-tit">
            <div class="sign-inf">
              <h1 v-if="!userName">用户登录</h1>
              <h1 v-else>您已登录</h1>
            </div>
          </div>
          <div class="sign-formbox">
            <div class="sign-form" v-if="!userName">
              <input type="text" v-model="user.telephone" class="account" />
              <input type="password" v-model="user.rep_password" class="password" />
              <button type="button" @click="login()" class="sign-submit">
                立即登录
              </button>
            </div>
            <div class="sign-form flex" v-else>
              <h2>欢迎人大代表 {{ userName }} 使用服务平台</h2>
              <button type="button" @click="loginTo()" class="sign-submit">
                进入履职
              </button>
              <button type="button" @click="outLogin()" class="sign-submit out">
                退出登录
              </button>
            </div>
          </div>
        </div>
        <div class="home-news">
          <div class="home-news-list">
            <div class="home-news-tit">
              <div class="home-news-name">综合新闻</div>
              <div class="home-news-more" @click="tosynthesizeNews()">
                <span>更多</span>
                <img src="@/images/more-bj.png" alt="" />
              </div>
            </div>
            <div class="home-news-main">
              <ul>
                <li v-for="item in synthesizeNews" :key="item.id" @click="toNewsdetail(item.id)">
                  {{ item.title }}
                </li>

              </ul>
            </div>
          </div>
          <div class="home-news-list">
            <div class="home-news-tit">
              <div class="home-news-name">通知公告</div>
              <div class="home-news-more" @click="noticeList()">
                <span>更多</span>
                <img src="@/images/more-bj.png" alt="" />
              </div>
            </div>
            <div class="home-news-main">
              <ul>
                <li v-for="item in announcement" @click="toNoticedetail(item.id)">
                  {{ item.title }}
                </li>
              </ul>
            </div>
          </div>
          <div class="home-news-list">
            <div class="home-news-tit">
              <div class="home-news-name">履职活动</div>
              <div class="home-news-more" @click="performList()">
                <span>更多</span>
                <img src="@/images/more-bj.png" alt="" />
              </div>
            </div>
            <div class="home-news-main">
              <ul>
                <li v-for="item in performActivity" @click="toPerformdetail(item.id)">
                  {{ item.title }}
                </li>
              </ul>
            </div>
          </div>
          <div class="home-news-list">
            <div class="home-news-tit">
              <div class="home-news-name">党建工作</div>
              <div class="home-news-more" @click="partyList()">
                <span>更多</span>
                <img src="@/images/more-bj.png" alt="" />
              </div>
            </div>
            <div class="home-news-main">
              <ul>
                <li v-for="item in partyBuilding" @click="toPartydetail(item.id)">
                  {{ item.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="box-main2">
          <div class="home-rd-tit">
            <div class="home-rd-titimg">
              <img src="@/images/home-tit-bj.jpg" alt="" />
            </div>
            <div class="home-rd-titinf">
              <div class="home-rd-name">省人大代表</div>
            </div>
            <div class="home-rd-more" @click="topeopleList('5a9474febb8945fdafe997fcccb474bd')">
              <span>更多</span>
              <img src="@/images/more-bj.png" alt="" />
            </div>
          </div>
          <div class="home-rd-item">
            <ul>
              <li v-for="item in provincePeople" @click="toDeputies(item.id)">
                <a>
                  <i class="imgbox_a">
                    <img :src="imgUrl + item.avatar" alt="" />
                  </i>
                  <span>
                    <h1>{{ item.rep_name }}</h1>
                    <h2>{{ item.unit }}</h2>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div> -->
        <div class="box-main2">
          <div class="home-rd-tit">
            <div class="home-rd-titimg">
              <img src="@/images/home-tit-bj.jpg" alt="" />
            </div>
            <div class="home-rd-titinf">
              <div class="home-rd-name">市人大代表</div>
            </div>
            <div class="home-rd-more" @click="topeopleList('485dde57587a4213980dd4f4cb40de1f')">
              <span>更多</span>
              <img src="@/images/more-bj.png" alt="" />
            </div>
          </div>
          <div class="home-rd-item">
            <ul>
              <li v-for="item in cityPeople" @click="toDeputies(item.id)">
                <a>
                  <i class="imgbox_a">
                    <img :src="imgUrl + item.avatar" alt="" />
                  </i>
                  <span>
                    <h1>{{ item.rep_name }}</h1>
                    <h2>{{ item.unit }}</h2>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="box-main2">
          <div class="home-rd-tit">
            <div class="home-rd-titimg">
              <img src="@/images/home-tit-bj.jpg" alt="" />
            </div>
            <div class="home-rd-titinf">
              <div class="home-rd-name">县人大代表</div>
            </div>
          </div>

          <div class="home-news-tit tit-mb">
            <div class="home-news-name home-news-name2">
              县人大常委会组成人员
            </div>
            <div class="home-news-more" @click="tocountypeopleList('d7d91b2ab0fe42d881318e89acc68243')">
              <span>更多</span>
              <img src="@/images/more-bj.png" alt="" />
            </div>
          </div>
          <div class="home-rd-item" v-if="countyCommittee">
            <ul>
              <li v-for="item in countyCommittee" :key="item.id" @click="toDeputies(item.id)">
                <a>
                  <i class="imgbox_a">
                    <img :src="imgUrl + item.avatar" alt="" />
                  </i>
                  <span>
                    <h1>{{ item.rep_name }}</h1>
                    <h2>{{ item.unit }}</h2>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="home-rd-item" v-else>
            <span>暂无数据</span>
          </div>

          <div class="home-news-tit tit-mb tit-mt">
            <div class="home-news-name home-news-name2">县人大代表</div>
            <div class="home-news-more" @click="tocountypeopleList('fc02366f6f3c4fca9f4e3a724b27f0a8')">
              <span>更多</span>
              <img src="@/images/more-bj.png" alt="" />
            </div>
          </div>
          <div class="home-rd-item" v-if="countyPeople">
            <ul>
              <li v-for="item in countyPeople" @click="toDeputies(item.id)">
                <a>
                  <i class="imgbox_a">
                    <img :src="imgUrl + item.avatar" alt="" />
                  </i>
                  <span>
                    <h1>{{ item.rep_name }}</h1>
                    <h2>{{ item.unit }}</h2>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="home-rd-item" v-else>
            <span>暂无数据</span>
          </div>
        </div>
        <div class="box-main2">
          <div class="home-rd-tit">
            <div class="home-rd-titimg">
              <img src="@/images/home-tit-bj.jpg" alt="" />
            </div>
            <div class="home-rd-titinf">
              <div class="home-rd-name">乡镇人大代表</div>
            </div>
          </div>
          <div class="home-xz-name tit-mb">
            <span v-for="(item, index) in gangu_towns_area" :key="item.id" @click="townspeolple(index)"  :class="peopleIndex === index ? 'active' : ''">
              {{ item.value }}
            </span>
          </div>
          <div v-if="peopleIndex == 0">
            <div class="home-rd-item">
              <ul>
                <li v-for="item in townsPeople" :key="item.id" @click="toDeputies(item.id)">
                  <a>
                    <i class="imgbox_a">
                      <img :src="imgUrl + item.avatar" alt="" />
                    </i>
                    <span>
                      <h1>{{ item.rep_name }}</h1>
                      <h2>{{ item.unit }}</h2>
                    </span>
                  </a>
                </li>
              </ul>
              <div class="reading-more"  @click="totownpeopleList(gangu_towns_area[0].id)">
                查看更多
              </div>
            </div>
          </div>
          <div v-else>
            <townsPeople :msg="[this.townsPeople,this.num]" ></townsPeople>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import * as api from "@/utils/http";
import townsPeople from "@/views/Home/TownsPeople";
import * as cookie from "@/utils/cookie";
import moment from "moment";

export default {
  data() {
    return {
      rep_sort: [],
      user: {},
      userName: null,
      gangu_towns_area: [], //乡镇列表
      townsPeople: null, //乡镇人大
      synthesizeNews: null, //综合新闻
      notice_type: [], //通知
      provincePeople: null,
      cityPeople: null,
      countyPeople: null,
      countyCommittee: null,
      townPeople: null,
      announcement: null,
      partyBuilding: null, //党建工作
      performActivity: null, //履职活动
      peopleIndex: 0,
      imgUrl: "",
      num:0
    };
  },
  components: {
    townsPeople,
  },
  mounted() {
    this.init();
    this.userName = cookie.getCookie("user")
      ? cookie.getCookie("user").repName
      : null;
  },
  methods: {
    // 退出登录
    outLogin() {
      cookie.removeCookie("user");
      this.userName = null;
      this.$message.success("您已退出登录");
    },
    // 登录跳转
    loginTo() {
      const time = moment(new Date().getTime()).format("YYYY/MM/DD HH:mm:ss");
      let baseTooken = window.btoa(
        JSON.stringify({
          repId: cookie.getCookie("user").id,
          time: time,
        })
      );
      this.$message.success("已登录，为您跳转到履职平台");
      console.log(
        "http://101.200.163.56:8020/ggrd//index.html?token=" + baseTooken
      );
      // debugger;
      setTimeout(() => {
        window.open(
          "https://www.ggrdw.gov.cn/ggrd//index.html?token=" + baseTooken
        );
      }, 1000);
    },
    // 登录
    login() {
      api
        .post("/representativesController/loginByRep", this.user)
        .then((res) => {
          console.log("代表登录", res);
          if (res.code != 200) {
            this.$message.error(res.message + "，登录失败！");
          } else {
            cookie.setCookieAndDate(
              "user",
              {
                repName: res.data.repName,
                id: res.data.id,
              },
              "14d"
            );

            this.userName = cookie.getCookie("user")
              ? cookie.getCookie("user").repName
              : null;
            this.loginTo();
          }
        });
    },
    // 跳转综合新闻详情
    toNewsdetail(id) {
      this.$router.push(`/newsdetail/${id}`);
    },
    // 跳转通知公告详情
    toNoticedetail(id) {
      this.$router.push(`/newsdetail/${id}`);
    },
    // 跳转履职活动详情
    toPerformdetail(id) {
      this.$router.push(`/performActivity/performDetail/${id}`);
    },
    // 跳转党建工作详情
    toPartydetail(id) {
      this.$router.push(`/partywork/partydetail/${id}`);
    },
    // 跳转人大列表
    topeopleList(id) {
      this.$router.push(`/peoplelist/${id}`);
    },
    // 跳转县人大列表
    tocountypeopleList(id) {
      this.$router.push(`/countyPeopleList/${id}`);
    },
     // 跳转乡镇人大列表
     totownpeopleList(id) {
      this.$router.push(`/townPeopleList/${id}`);
    },
    // 乡镇人大详情
    peopleDetail() {
      this.$router.push(`/peoplelist`);
    },
    // 履职活动列表
    performList() {
      this.$router.push(`/performActivity`);
    },
    // 通知公告列表
    noticeList() {
      this.$router.push(`/notice`);
    },
    // 党建工作列表
    partyList() {
      this.$router.push(`/partyWork`);
    },
    // 跳转人大履职
    toDeputies(id) {
      this.$router.push(`/deputies/${id}`);
    },
    // 跳转乡镇人大履职
    toTownDeputies(id) {
      this.$router.push(`/towndeputies/${id}`);
    },

    // 跳转综合新闻
    tosynthesizeNews(id) {
      this.$router.push(`/synthesizeList`);
    },
    // 乡镇人大列表切换
    townspeolple(num) {
      this.peopleIndex = num;
      api.post("/representativesController/selectRepListByType", {
            limit: 5,
            page: 1,
            rep_sort: "ceb64afa806c4b06bde058a1ff4264ad",
            towns: this.gangu_towns_area[this.peopleIndex].id
          })
        .then((res) => {
          console.log("乡镇人大列表1", res,this.gangu_towns_area[num].id,num);
          this.townsPeople = res.data;
          this.num = num
        });
    },
    async init() {
      await api
        .post("/dictController/getDictListByType", { type: "rep_sort" })
        .then((res) => {
          console.log("人大类型00", res.data);
          this.rep_sort = res.data;
        });

      await api
        .post("/dictController/getDictListByType", { type: "notice_type" })
        .then((res) => {
          console.log("通知类型", res.data);
          this.notice_type = res.data;
        });

      await api
        .post("/dictController/getDictListByType", { type: "notice_type" })
        .then((res) => {
          console.log("综合新闻11", res);

        });

      // let pams = {
      //   limit: 10,
      //   page: 1,
      //   rep_sort: this.rep_sort[0].id,
      // };
      await api
        .post("/representativesController/selectRepListByType", {
          limit: 5,
          page: 1,
          rep_sort: this.rep_sort[0].id,
        })
        .then((res) => {
          console.log("全国人大代表", res.data);
        });

      await api
        .post("/representativesController/selectRepListByType", {
          limit: 5,
          page: 1,
          rep_sort: "5a9474febb8945fdafe997fcccb474bd",
        })
        .then((res) => {
          this.imgUrl = api.baseURL
          this.provincePeople = res.data;
          console.log("省级人大代表", res.data);
        });

      await api
        .post("/representativesController/selectRepListByType", {
          limit: 5,
          page: 1,
          rep_sort: "485dde57587a4213980dd4f4cb40de1f",
        })
        .then((res) => {
          console.log("市级人大代表", res.data);
          this.cityPeople = res.data;
        });
      await api
        .post("/dictController/getDictListByType", {
          type: "county_organization",
        })
        .then((res) => {
          console.log("县人大列表", res.data);
        });
      await api
        .post("/representativesController/selectRepListByType", {
          limit: 5,
          page: 1,
          rep_sort: "ceb64afa806c4b06bde058a1ff4264ad",
          county_organization: "d7d91b2ab0fe42d881318e89acc68243",
        })
        .then((res) => {
          console.log("县人大常委会组成人员", res.data);
          this.countyCommittee = res.data;
        });
      await api
        .post("/representativesController/selectRepListByType", {
          limit: 5,
          page: 1,
          rep_sort: "ceb64afa806c4b06bde058a1ff4264ad",
          county_organization: "fc02366f6f3c4fca9f4e3a724b27f0a8",
        })
        .then((res) => {
          this.countyPeople = res.data;
          console.log("县人大代表00", this.countyPeople);
        });


      await api
        .post("/dictController/getDictListByType", { type: "gangu_towns_area" })
        .then((res) => {
          console.log("乡镇列表", res.data);
          this.gangu_towns_area = res.data;

          api.post("/representativesController/selectRepListByType", {
            limit: 5,
            page: 1,
            rep_sort: "ceb64afa806c4b06bde058a1ff4264ad",
            towns: this.gangu_towns_area[this.peopleIndex].id
          })
            .then((res) => {
              console.log("乡镇人大代表==", res.data , this.gangu_towns_area[this.peopleIndex].id);
              this.townsPeople = res.data;
            });
        });


      await api
        .post("/noticeController/getNoticeList", {
          limit: 7,
          page: 1,
          column_type: "14da2b2d70ed4b069442faf47392dc96",
          audit_status: 3,
          is_delete:2
        })
        .then((res) => {
          console.log("通知公告11", res);
          this.announcement = res.data;
        });

      await api
        .post("/partyConstructionController/getPartyConstructionList", {
          limit: 7,
          page: 1,
        })
        .then((res) => {
          console.log("党建工作", res.data);
          this.partyBuilding = res.data;
        });

      await api
        .post("/performDutiesController/getPerformDutiesList", {
          limit: 7,
          page: 1,
        })
        .then((res) => {
          console.log("履职活动", res.data);
          this.performActivity = res.data;
        });

      // await api
      //   .post("/ganguTownsAreaController/getGanguTownsAreaList", {
      //     limit: 7,
      //     page: 1,
      //     area_type: this.gangu_towns_area[0].id,
      //   })
      //   .then((res) => {
      //     console.log("乡镇人大列表0", res);
      //     this.townsPeople = res.data;
      //   });
      await api
        .post("/noticeController/getNoticeList", {
          limit: 5,
          page: 1,
          column_type: "8d8322bcaf324fcb8c7fb9bbd8a49e8f",
          audit_status:3,
          is_delete:2
        })
        .then((res) => {
          console.log("综合新闻", res.data);
          this.synthesizeNews = res.data
        });
    },
  },
};
</script>
<style scoped>
.sign-box {
  margin-top: 28px;
  margin-bottom: 28px;
  height: 96px;
  background: #fdd697;
  display: flex;
}

.sign-tit {
  width: 438px;
  height: 96px;
  flex-shrink: 0;
  position: relative;
  background: url(@/images/sign-img.jpg) no-repeat center;
}

.sign-img {
  height: 96px;
  position: absolute;
  z-index: 1;
}

.sign-img i.imgbox_a {
  padding-bottom: 51.84%;
}

.sign-inf {
  height: 96px;
  position: absolute;
  z-index: 2;
  color: #fff;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 32px;
}

.sign-inf h1 {
  font-size: 26px;
  margin: 0px;
}

.sign-inf h2 {
  font-size: 17px;
  margin: 0px;
  font-weight: normal;
  margin-top: 10px;
}

.sign-formbox {
  height: 96px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sign-form {
  height: 58px;
}

.sign-form input.account,
.sign-form input.password {
  width: 230px;
  height: 52px;
  background: #fff;
  border: none;
  margin-right: 12px;
  padding: 0 20px;
}

.sign-submit {
  width: 128px;
  height: 52px;
  background: #fa744a;
  color: #fff;
  border: none;
}

.sign-formbox span {
  margin-top: 10px;
  color: #db4836;
}

.box-menu-list {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
}

.box-menu-name {
  width: 102px;
  height: 38px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ef1515;
  color: #fff;
  margin-right: 20px;
}

.box-menu-item {
  flex-grow: 1;
  display: flex;
  justify-content: start;
}

.box-menu-item span {
  padding: 3px 14px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-menu-item span.cur {
  background: #f0f0f0;
}

.box-menu-item span:hover {
  background: #f0f0f0;
}

.box-main2 {
  padding-bottom: 28px;
}

.box-main2 ul {
  margin-right: -18px;
  overflow: hidden;
}

.box-main2 ul li {
  width: 20%;
  padding-right: 18px;
  float: left;
  box-sizing: border-box;
}

.box-main2 ul li a {
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  width: 100%;
  display: block;
  padding: 18px;
  box-sizing: border-box;
  overflow: hidden;
}

.box-main2 ul li a i.imgbox_a {
  padding-bottom: 132%;
}

.box-main2 ul li a span h1 {
  font-size: 16px;
  margin: 0px;
  margin-top: 15px;
}

.box-main2 ul li a span h2 {
  font-size: 15px;
  color: #999999;
  font-weight: normal;
  margin: 0px;
  margin-top: 5px;
}

.home-rd-item>span {
  color: #999;
}

.home-xz-name {
  background: #f3f3f3;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
}

.home-xz-name span {
  padding: 10px 12px;
  font-size: 16px;
}
.home-xz-name span.active{
  color: #db4836;
  font-weight: bolder;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 660px;
}
.home-rd-item ul li a span h2 {
  font-size: 15px;
  color: #999999;
  font-weight: normal;
  margin: 0px;
  margin-top: 5px;
  height: 40px;
}
.out {
  background: #f3f3f3;
  color: #5f5d5d;
}
/* .home-rd-item {
  padding-bottom: 38px;
} */
.reading-more{
  border: 1px solid #ccc;
  width: 180px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  margin: 0px auto;
  margin-top: 24px;
}
.reading-more:hover{
  cursor: pointer;
  color: #db4836;
  border: 1px solid #db4836;
}
</style>
@import '@/assets/css/common.css'
