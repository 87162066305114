<template>
  <div class="content">
    <div class="container">
      <div class="box">
          <div class="news-details">
            <div class="news-details-tit">
              <h1>{{ detail.title}}</h1>
              <h2>
                <span> {{detail.title}}</span>
                <span>发稿日期：{{detail.createDate | time('YYYY/MM/DD') }}</span>
              </h2>
            </div>
            <div class="news-details-main" v-html="detail.content">
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as api from "@/utils/http";
export default {
  data() {
    return {
      detail:[]
    }
  },
  components: {
  },
  mounted() {
    this.init()
  },
  methods: {
   
    //html转化过滤其他标签方法
    filterHtml: function (str) {
      let reg = new RegExp("<[^>]+>", "gi");
      if (typeof str != "string") {
        //不是字符串
        return str;
      }
      str = str.replace(/&nbsp;/gi, "");
      return str.replace(reg, "");
    },
    async init() {
      await api
        .post("/performDutiesController/selectPerformDutiesById", {
          performDutiesId : this.$route.params.id,
        })
        .then((res) => {
          this.detail = res.data.bizPerformDutiesActivityModel
          console.log("履职", res);
        });
    }

  }
}
</script>
<style scoped>
.content {
  background: #f3edd0;
}

.box {
  background: #fff;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
}
.box-right {
  border: 1px solid #e4e4e4;
  min-height: 800px;
  overflow: hidden;
  padding: 24px;
  box-sizing: border-box;
}
.news-details-main{
  
}

</style>
@import '@/assets/css/common.css'