<template>
  <div class="content">
    <div class="container">
      <div class="box">
        <div class="box-left fl">
          <div class="member-pic">
            <i class="imgbox_a">
              <img :src="imgUrl + representatives.avatar" alt="">
            </i>
          </div>
          <div class="member-inf">
            <h1>
              <span>
                {{representatives.repName}}
              </span>
              <span>
                全国人大代表
              </span>
            </h1>
            <h2>
             {{representativesInfo.personalProfile}}
            </h2>
          </div>
          <div class="evaluation">
            <div class="evaluation-tit">
              您对此次的评价为：
            </div>
            <div class="evaluation-list">
              <div class="evaluation-list-tit">
                <span>满意</span>
                <span>不满意</span>
              </div>
              <div class="evaluation-list-inf">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="box-right">
          <div class="news-details">
              <div class="news-details-tit">
                <h1>区四届人大常委会举行第六次会议</h1>
                <h2>
                  <span>稿源：滨海新区人大</span>
                  <span>发稿日期：2022-08-11 08:43</span>
                </h2>
              </div>
              <div class="news-details-main">
                <p>
                  8月9日，区四届人大常委会召开第六次会议。区人大常委会主任王洪茹主持会议。区人大常委会副主任沈志勇、武广华、张国盛、窦
广春、张金友出席会议。区人民政府副区长尹晓峰，区人民法院院长张长山，区人民检察院检察长陈振兴，区监察委员会、区人大常委会
办公室、区人民政府办公室有关负责同志列席会议。
                </p>
                <p>
                  会议传达学习了天津市第十七届人民代表大会第七次会议精神。会议指出，市十七届人大七次会议是在迎接党的二十大召开这个时间节点上召开的一次重要会议。要牢牢把握国家政权机关、政治机关定位，把捍卫“两个确立”、做到“两个维护”作为首要的政治纪律和政治规矩，坚决拥戴核心、忠诚核心、维护核心。要始终坚持以人民为中心的发展思想，深入践行全过程人民民主，不断强化同人民群众、人大代表的联系。要围绕中心服务大局，全面提升工作质效，切实担负起宪法法律赋予的各项职责，助力经济社会发展和改革攻坚任务，为全面建设新时代美丽“滨城”，加快打造高质量发展示范区凝聚人大智慧、贡献人大力量。
                </p>
                <p>
                  会议学习传达贯彻了《天津市区人民代表大会常务委员会街道工作委员会工作条例》。会议强调，市十七届人大常委会第三十六次会议审议通过的《天津市区人民代表大会常务委员会街道工作委员会工作条例》（以下简称《条例》），对我市区人民代表大会常务委员会街道工作委员会的工作作出了法治化、制度化、规范化安排，对做好新时代街道人大工作，提高和完善基层治理体系和治理效能具有重要意义。区人大常委会组成人员和与会同志要带头学习
                </p>
              </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import * as api from "@/utils/http";
export default {
  name: 'HomeView',
  data() {
    return {
      imgUrl:"",
      representatives:[],
      representativesInfo:[]
    };
  },
  components: {
  },
  mounted() {
    api
      .post("/representativesController/selectRepModelById", 
      { 
        repId: this.$route.params.id
      })
      .then((res) => {
        this.imgUrl = api.baseURL
        this.representatives = res.data.bizRepresentativesModel,
        this.representativesInfo = res.data.bizRepresentativesInfoModel,
        console.log("详情",res);

      });
  }
}
</script>
<style scoped>
.content {
  background: #f3edd0;
}

.box {
  background: #fff;
  padding: 24px;
  padding-bottom: 0px;
  box-sizing: border-box;
  overflow: hidden;
}

.box-left {
  width: 294px;
  margin-right: 24px;
  box-sizing: border-box;
  background: #f4f0f0;
  padding: 24px;
  box-sizing: border-box;
}

.member-pic {
  width: 214px;
  border: 14px solid #fff;
}

.member-pic i.imgbox_a {
  padding-bottom: 131.78%;
}

.member-inf h1 {
  text-align: left;
  margin: 0px;
  margin-top: 10px;
}

.member-inf h1 span:first-child {
  font-size: 20px;
  margin-right: 5px;
  font-weight: bolder;
  color: #333333;
}

.member-inf h1 span {
  font-size: 16px;
  font-weight: normal;
  color: #333333;
}

.member-inf h2 {
  margin: 0px;
  margin-top: 6px;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
  color: #333333;
}

.evaluation {
  margin-top: 46px;
}

.evaluation-tit {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: bolder;
  text-align: left;
  position: relative;
  padding-left: 12px;
}

.evaluation-tit::before {
  content: "";
  width: 4px;
  height: 20px;
  position: absolute;
  left: 0px;
  background: #d83d32;
}

.evaluation-list-tit {
  overflow: hidden;
  margin-top: 12px;
}

.evaluation-list-tit span {
  width: 33.33%;
  display: block;
  float: left;
  text-align: center;
}

.evaluation-list-inf {
  margin-top: 8px;
  display: flex;
  justify-content: space-around;
}

.evaluation-list-inf span {
  width: 29px;
  height: 29px;
  display: flex;
  background: url(../../images/star.png);
}

.box-right {
  border: 1px solid #e4e4e4;
  min-height: 800px;
  overflow: hidden;
  padding: 24px;
  box-sizing: border-box;
}


</style>
@import '@/assets/css/common.css'